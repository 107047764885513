import { useBuildingConnectedAuthFlowStatus } from 'features/BuildingConnected/hooks/useBuildingConnectedAuthFlowStatus';
import { useMutation } from '@tanstack/react-query';
import { Resources } from 'api/Resources';
import { ApiService } from 'api/ApiService';
import logger from 'loglevel';

export const useBuildingConnectedLoginMutation = () => {
  const { buildConnectedAuthFlowStatus, setBuildConnectedAuthFlowStatus } =
    useBuildingConnectedAuthFlowStatus();

  const buildingConnectedLoginMutation = useMutation({
    mutationFn: ({ code }: { code: string }) => {
      const endpoint = Resources.BUILDING_CONNECTED_LOGIN;
      return ApiService.post(endpoint, { authorization_code: code });
    },
    onSuccess: () => {
      const { redirect } = buildConnectedAuthFlowStatus;
      if (redirect) {
        logger.info('Redirecting to', redirect);
        setTimeout(() => {
          window.location.replace(
            `${redirect}${redirect.includes('?') ? '&' : '?'}goBackSteps=-2`,
          );
        }, 200);
      }
      setBuildConnectedAuthFlowStatus({
        status: 'authorized',
      });
    },
  });

  return { buildingConnectedLoginMutation };
};
