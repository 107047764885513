import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { PrivateURL, URL } from 'Urls';
import PrivateLayout from 'layouts/Private.layout';
import { getProjectCurrentStep, replaceProjectIdPath } from 'hooks/navigate';
import {
  LAST_VISITED_PAGE,
  LAST_VISITED_PROJECT_ID,
  PENDING_PROJECT_REDIRECT,
} from 'utils/constants';
// import { useSwitchProject } from 'features/Projects/hook/project';
import { LoadingPage } from 'components/LoadingPage/LoadingPage';
import log from 'loglevel';
import { NotFoundView } from 'views/auth/NotFound404View';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { useProjectById } from 'features/Projects/hook/useProjectById';
import { useKeycloak } from 'features/Auth/hook/useKeycloack';
import { isEmpty } from 'lodash-es';
import { ErrorPage } from 'features/Auth/ErrorPage/ErrorPage';

export const PrivateRoute = (props: RouteProps) => {
  const { selectedProjectId } = useSelectedProjectId();
  const location = useLocation();
  const isRootPath = location.pathname === URL.ROOT;
  const { isAuthLoading, isAuthenticated } = useKeycloak();

  const lastVisitedProjectId = localStorage.getItem(LAST_VISITED_PROJECT_ID);
  const projectId =
    selectedProjectId ||
    (lastVisitedProjectId ? parseInt(lastVisitedProjectId) : undefined);
  const { projectByIdQuery } = useProjectById({
    id: projectId,
    enabled: !isAuthLoading && isAuthenticated,
  });

  if ((!isRootPath && projectByIdQuery.isLoading) || isAuthLoading) {
    return <LoadingPage />;
  }

  if (!isAuthLoading && projectByIdQuery.status === 'error') {
    localStorage.removeItem(LAST_VISITED_PROJECT_ID);

    return <NotFoundView />;
  }
  if (!isAuthLoading && !isAuthenticated) {
    return <ErrorPage />;
  }

  const pendingProjectRedirect = localStorage.getItem(PENDING_PROJECT_REDIRECT);
  let redirectToPath: string | null = null;

  if (pendingProjectRedirect) {
    log.debug('There is a pending redirect');
    const projectIdPendingRedirect = parseInt(pendingProjectRedirect);
    localStorage.removeItem(PENDING_PROJECT_REDIRECT);

    if (!isNaN(projectIdPendingRedirect)) {
      log.debug('Would redirect to project ID ', projectIdPendingRedirect);
      redirectToPath = replaceProjectIdPath(
        PrivateURL.PROJECT_UPDATE,
        projectIdPendingRedirect,
      );
    }
  } else if (isRootPath) {
    log.debug('Set redirect, as we are in root');
    if (sessionStorage.getItem(LAST_VISITED_PAGE)) {
      redirectToPath = sessionStorage.getItem(LAST_VISITED_PAGE);
    } else if (!isEmpty(projectByIdQuery.data) && lastVisitedProjectId) {
      redirectToPath = replaceProjectIdPath(
        getProjectCurrentStep(projectByIdQuery.data),
        lastVisitedProjectId,
      );
    } else {
      log.debug('Would redirect projects list');
      redirectToPath = PrivateURL.PROJECT_LIST;
    }
  }

  return (
    <Route
      {...props}
      render={(renderProps) =>
        redirectToPath ? (
          <Redirect
            to={{
              pathname: redirectToPath,
              state: { from: renderProps.location },
            }}
          />
        ) : (
          <PrivateLayout />
        )
      }
    />
  );
};
