import { useSessionActions } from 'features/Auth/hook/session';
import { useEffect } from 'react';
import { History, URL as SiteURL } from 'Urls';
import log from 'loglevel';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';

export function LogoutView() {
  const { clearSessionData } = useSessionActions();
  const { currentUser } = useCurrentUser();
  useEffect(() => {
    log.debug('Log user out');
    const logoutAndRedirect = async () => {
      try {
        await clearSessionData();
      } finally {
        setTimeout(() => {
          if (
            currentUser?.companies.length !== undefined &&
            currentUser.companies.length > 1
          ) {
            const { host, protocol } = new URL(window.location.href);
            const hostParts = host.split('.');
            const baseUrl = `${protocol}//${hostParts.slice(1).join('.')}`;
            log.debug(`Redirecting to ${baseUrl}`);
            window.location.replace(baseUrl);
          } else {
            History.push(SiteURL.ROOT);
          }
        }, 0);
      }
    };
    logoutAndRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
