import log from 'loglevel';
import { isDev, isPreview, isProdBuild } from 'utils/environment';
import Cookies from 'js-cookie';
import { TOKEN_SS_KEY } from '../constants';
import { Session } from '../types';
import { AuthenticationService } from 'api/AuthenticationService';

const getCookieDomain = () => {
  if (isPreview()) {
    return import.meta.env.VITE_VERCEL_BRANCH_URL;
  }

  const url = new URL(window.location.href);

  if (isDev() && url.hostname.endsWith('app.localhost')) {
    return 'app.localhost';
  }

  // Get the 3 last elements of the hostname
  return '.' + url.hostname.split('.').slice(-3).join('.');
};

const cookieConfig = {
  domain: `.${getCookieDomain()}`,
  path: '/',
  secure: !isPreview() && isProdBuild() && import.meta.env.VITE_E2E !== 'true',
};

export const setCookie = (key: string, value: string) => {
  Cookies.set(key, value, cookieConfig);
};

export const getCookie = (key: string) => {
  return Cookies.get(key);
};

export const removeCookie = (key: string) => {
  Cookies.remove(key, cookieConfig);
};

export const hasSession = () => !!AuthenticationService.getInstance().isAuthenticated;

// export const setSession = (session: Session) => {
//   if (session.access) {
//     setCookie(TOKEN_SS_KEY, JSON.stringify(session));
//   }
// };

export const getSession = (): Session | null => {
  const sessionCookie = Cookies.get(TOKEN_SS_KEY);
  return sessionCookie ? (JSON.parse(sessionCookie) as Session) : null;
};

export const clearSession = () => {
  log.debug('Clearing session');
  removeCookie(TOKEN_SS_KEY);
};
