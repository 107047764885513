import { History, PublicURL } from 'Urls';
import { useQueryClient } from '@tanstack/react-query';
import { useClearProjectData } from 'features/Projects/hook/project';
import { useCurrentUser } from './useCurrentUser';
import { clearSession } from './sessionHelpers';
import { useKeycloak } from './useKeycloack';
import logger from 'loglevel';

const USERNAME_LS_KEY = 'concntric-login-last-username';

const useClearCacheData = () => {
  const queryClient = useQueryClient();
  const clearCacheData = () => {
    queryClient.cancelQueries();
    queryClient.clear();
  };
  return {
    clearCacheData,
  };
};

export const useSessionActions = () => {
  const { clearProjectData } = useClearProjectData();
  const storedUsername = localStorage.getItem(USERNAME_LS_KEY);
  const { clearCurrentUser } = useCurrentUser();
  const { clearCacheData } = useClearCacheData();

  const { keycloak } = useKeycloak();
  const { currentUser } = useCurrentUser();

  const clearSessionData = async () => {
    clearSession();
    clearProjectData();
    clearCurrentUser();
    clearCacheData();
    return keycloak?.logout();
  };

  const logout = async () => {
    await clearSessionData();
    if (currentUser?.companies.length !== undefined && currentUser.companies.length > 1) {
      const { host, protocol } = new URL(window.location.href);
      const hostParts = host.split('.');
      const baseUrl = `${protocol}//${hostParts.slice(1).join('.')}${PublicURL.LOGOUT}`;
      logger.debug(`Logging out to ${baseUrl}`);
      window.location.replace(baseUrl);
    } else {
      History.push(PublicURL.LOGOUT);
    }
  };

  // return { clearSessionData, storedUsername, login, logout };
  return { clearSessionData, storedUsername, logout };
};
