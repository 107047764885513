import { getCurrentTenantSlug } from 'utils/helpers';

export type BuildingConnectedAuthState = {
  tenant: string;
};

export const buildingConnectedAuthState = {
  getBuildingConnectedAuthState: () => {
    const state = { tenant: getCurrentTenantSlug() } as BuildingConnectedAuthState;
    const stateStr = JSON.stringify(state);
    return btoa(stateStr);
  },
  parseBuildingConnectedAuthState: (state: string) => {
    const decodedState = atob(state);
    return JSON.parse(decodedState) as BuildingConnectedAuthState;
  },
};
