import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, SxProps, Theme } from '@mui/material';
import { PrivateURL } from 'Urls';
import { Permissions } from 'features/Auth/Permissions';
import usePermissions from 'features/Auth/hook/usePermissions';
import { Link } from 'react-router-dom';
import { joinSx } from 'utils/helpers';
import { useMixpanel } from 'hooks/useMixpanel';

type NewProjectButtonProps = {
  sx: SxProps<Theme>;
};

export const NewProjectButton = (props: NewProjectButtonProps) => {
  const { HasPerm } = usePermissions();
  const { mixpanel } = useMixpanel();

  return (
    <HasPerm permission={Permissions.ADD_PROJECT}>
      <Button
        data-testid="new-project"
        component={Link}
        variant="blueGhost"
        size="small"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        to={PrivateURL.PROJECT_NEW_OPTIONS}
        sx={joinSx({ borderRadius: '16px', px: 1.5 }, props.sx)}
        onClick={() => {
          mixpanel.click('new project', 'top navigation bar');
        }}
      >
        Create Project
      </Button>
    </HasPerm>
  );
};
