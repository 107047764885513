import { Stack } from '@mui/material';
import { PublicURL, URL, UrlRoute } from 'Urls';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ToastDialogs } from 'components/ToastDialogs';
import { Footer } from './Footer';

import { useQueryParam } from 'hooks/useQueryParam';
import { Suspense } from 'react';
import { LoadingPage } from 'components/LoadingPage/LoadingPage';
import { useTitle } from 'hooks/useTitle';
import { PublicRoutes } from 'Routes';
import { ErrorView } from 'views/auth/error';

export default function PublicLayout() {
  const queryParam = useQueryParam<{ token?: string }>();
  const token = queryParam.token;
  const views = [
    ...PublicRoutes.map((route, key) => {
      return (
        <Route
          path={route.path}
          render={(routeProps) => {
            if (route.path === PublicURL.COMPLETE_SIGNUP && !token) {
              return (
                <Redirect
                  to={{
                    pathname: URL.ROOT,
                    state: { from: routeProps.location },
                  }}
                />
              );
            } else {
              return <RouteRender route={route} />;
            }
          }}
          key={key}
        />
      );
    }),
    // If we are handling a public URL and there are no explicit route matching it
    // we need to redirect to the login page and avoid displaying an empty page
    <Route key="default" path="*" render={() => <ErrorView />} />,
  ];

  return (
    <Stack
      direction="column"
      sx={{ minHeight: '100lvh', backgroundColor: 'grey.100' }}
      className="publicLayout"
    >
      <ToastDialogs />
      <Stack component="main" sx={{ flex: 1 }}>
        <Suspense fallback={<LoadingPage />}>
          <Switch>{views}</Switch>
        </Suspense>
      </Stack>
      <Footer isPublicLayout />
    </Stack>
  );
}

function RouteRender({ route }: { route: UrlRoute }) {
  useTitle(route.name);
  return <route.component />;
}
