const nonPreviewBranches = [
  'master',
  'production',
  'productionbeta',
  'sales',
  'main',
  'staging',
];

export const isDev = () => import.meta.env.VITE_ENV === 'development';
export const isMaintenance = () => import.meta.env.VITE_MAINTENANCE === 'true';
export const isTesting = () => import.meta.env.VITE_ENV === 'testing';
export const isPreview = () => {
  return (
    typeof import.meta.env.VITE_VERCEL_GIT_COMMIT_REF !== 'undefined' &&
    nonPreviewBranches.indexOf(import.meta.env.VITE_VERCEL_GIT_COMMIT_REF) === -1
  );
};

export const isProd = () => import.meta.env.VITE_ENV === 'production';

export const isProdBuild = () => process.env.NODE_ENV === 'production';

export const isByPassAuth = (getCookie: (cookieName: string) => string | undefined) =>
  !isProd() && import.meta.env.VITE_CONCNTRIC_BYPASS_AUTH_COOKIE_NAME !== undefined && getCookie(import.meta.env.VITE_CONCNTRIC_BYPASS_AUTH_COOKIE_NAME);

export const APP_VERSION = process.env.APP_VERSION;
export const GIT_HASH = process.env.GIT_HASH;
export const GIT_LONG_HASH = process.env.GIT_LONG_HASH;
