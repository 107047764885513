import { Box, Button, Stack, Typography } from '@mui/material';
import { useSessionActions } from '../hook/session';
import { isDev } from 'utils/environment';
import { History, PublicURL } from 'Urls';
import { AuthPagePaper } from 'components/PageContainer/AuthPagePaper';
import newLogo from 'assets/img/concntric-logo-light.svg';
import { faKeySkeleton } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ErrorPage = () => {
  const { clearSessionData } = useSessionActions();
  const handleGoBack = () => {
    clearSessionData();
    // There may be the case where the use is trying to log in from an URL that
    // specify an invalid tenant
    const { host, protocol } = new URL(window.location.href);
    const hostParts = host.split('.');
    if (isDev() && hostParts.length > 0 && hostParts.at(-1)?.includes('localhost')) {
      window.location.replace(`${protocol}//app.${hostParts.at(-1)}`);
    } else if (hostParts.length >= 4) {
      window.location.replace(`${protocol}//${hostParts.slice(-3).join('.')}`);
    } else {
      History.push(PublicURL.LOGOUT);
    }
  };

  return (
    <AuthPagePaper>
      <Box component="img" src={newLogo} sx={{ width: '215px', mb: 4 }} />
      <Stack>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '92px',
              width: '92px',
              backgroundColor: 'grey.75',
              borderRadius: '92px',
              mb: 3,
            }}
          >
            <Box
              component={FontAwesomeIcon}
              icon={faKeySkeleton}
              sx={{ height: '36px', width: '36px', color: 'grey.500' }}
            />
          </Box>
        </Box>

        <Box sx={{ px: 4 }}>
          <Typography
            variant="h3SemiBold"
            component="h1"
            sx={{ mb: 2, textAlign: 'center' }}
          >
            We can&apos;t log you in right now
          </Typography>
          <Typography sx={{ mb: 3 }}>
            Please try to log in again or Contact your ConCntric administrator for help.
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ py: 1 }}
            onClick={handleGoBack}
          >
            Log in again
          </Button>
        </Box>
      </Stack>
    </AuthPagePaper>
  );
};
