import { atomWithStorage } from 'jotai/utils';
import { User } from 'types/User';
import { ApiService } from 'api/ApiService';
import { useQuery } from '@tanstack/react-query';
import { Resources } from 'api/Resources';
import { useAtom } from 'jotai';
import log from 'loglevel';
import {
  addSearchParamsToUrl,
  getCurrentTenantSlug,
  setPreviewTenant,
} from 'utils/helpers';
import { UserCompany } from 'types/Company';
import { mixpanelActions } from 'services/Mixpanel';
import { queryKeys } from 'utils/reactQuery';
import { isDev, isPreview } from 'utils/environment';
import { isEmpty } from 'lodash-es';
import { getCookie, setCookie } from './sessionHelpers';
import * as Sentry from '@sentry/react';

const CURRENT_USER_LS_KEY = 'concntric-current-user-v3';
const LAST_TENANT_SLUG_KEY = 'concntric-last-tenant-slug';

export const currentUserAtom = atomWithStorage<User | null>(CURRENT_USER_LS_KEY, null);

currentUserAtom.debugLabel = 'currentUserAtom';

const getCurrentUserCompanies = async (signal?: AbortSignal) => {
  try {
    const responseData = await ApiService.get(Resources.CURRENT_USER_COMPANIES, {
      signal,
    }).then(({ data }) => data);
    return responseData as UserCompany[];
  } catch (error) {
    log.error(
      error instanceof Error
        ? error.message
        : error || 'Current user companies could not be loaded',
    );
  }
};

export function useCheckUserByEmail(email: string, token: string) {
  let url = Resources.EXIST_USER.toString();

  url = addSearchParamsToUrl({
    url,
    searchParams: {
      email: encodeURIComponent(email),
      token,
    },
  });

  return useQuery({
    queryKey: queryKeys.checkUser,
    queryFn: () => {
      return ApiService.get(url).then((res) => res.data);
    },

    staleTime: Infinity,
    enabled: Boolean(email) && Boolean(token),
  });
}

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useAtom(currentUserAtom);

  const isCurrentUserDomainValid = async () => {
    const userCompanies = await getCurrentUserCompanies();
    if (userCompanies && userCompanies.length) {
      const slug = getCurrentTenantSlug();
      if (slug && userCompanies.some((company) => company.slug === slug)) {
        log.debug('Logged user is in a valid tenant');
        return true;
      }
    } else {
      // There are some cases where, without session, the app tries to resolve the userCompanies
      log.error(
        'Can not redirect user to valid domain: there are no companies associated to this user',
      );
    }
    return false;
  };

  const loadCurrentUser = async () => {
    try {
      const responseData = await ApiService.get(Resources.CURRENT_USER, {}).then(
        ({ data }) => data as User,
      );
      setCurrentUser(responseData);
    } catch (error) {
      log.error(
        error instanceof Error
          ? error.message
          : error || 'Current user could not be loaded',
      );
    }
  };

  const validateTenantAndLoadUser = async ({
    isLogin = false,
  }: { isLogin?: boolean } = {}) => {
    const isTenantValid = await isCurrentUserDomainValid();
    const lastTenantSlug = getCookie(LAST_TENANT_SLUG_KEY);

    if (isTenantValid) {
      await loadCurrentUser();
      const currentSlug = getCurrentTenantSlug();
      if (currentSlug && currentSlug !== lastTenantSlug) {
        // CPE-5530: Save the last tenant slug in a cookie to redirect the user to the last tenant
        setCookie(LAST_TENANT_SLUG_KEY, currentSlug);
      }
    } else {
      const userCompanies = await getCurrentUserCompanies();
      // CPE-5530: Redirect the user to the last tenant if it exists or to the first one (previous behavior)
      const defaultCompany =
        !userCompanies || isEmpty(userCompanies)
          ? null
          : ((lastTenantSlug &&
              userCompanies.find((company) => company.slug === lastTenantSlug)) ??
            userCompanies[0]);

      if (defaultCompany) {
        if (isPreview()) {
          setPreviewTenant(defaultCompany.slug);
        } else {
          const url = new URL(window.location.href);
          const baseHostname =
            isDev() && url.hostname.endsWith('localhost')
              ? 'app.localhost'
              : url.hostname.split('.').slice(-3).join('.');
          log.debug('Redirecting user to a valid tenant: ' + url.toString());
          url.hostname = `${defaultCompany.slug}.${baseHostname}`;
          window.location.replace(url.toString());
        }

        if (isLogin) {
          mixpanelActions.track(defaultCompany, 'login');
        }
      } else {
        const message =
          'Can not redirect user to valid domain: there are no companies associated to this user';
        Sentry.captureMessage(message);
        log.error(message);
        throw Error('No active account found with the given credentials');
      }
    }
  };

  const clearCurrentUser = () => {
    setCurrentUser(null);
  };

  const updateCurrentUser = async (partialUser: Partial<User>) => {
    try {
      const responseData = await ApiService.patch(
        Resources.CURRENT_USER,
        partialUser,
      ).then(({ data }) => data as User);
      setCurrentUser(responseData);
    } catch (error) {
      log.error(
        error instanceof Error
          ? error.message
          : error || 'Current user could not be updated',
      );
    }
  };

  return {
    currentUser,
    validateTenantAndLoadUser,
    loadCurrentUser,
    clearCurrentUser,
    updateCurrentUser,
  };
};
