import { buildingConnectedAuthState } from 'features/BuildingConnected/buildingConnectedAuthState';

import logger from 'loglevel';

export const buildingConnectedRedirectToTenant = () => {
  const { parseBuildingConnectedAuthState } = buildingConnectedAuthState;
  const { pathname, search, href } = window.location;
  if (pathname === '/building-connected/') {
    const urlParams = new URLSearchParams(search);
    const components = href.split('//');
    const stateStr = urlParams.get('state');
    if (!stateStr) {
      logger.error('No state found in the URL');
      return;
    }
    const state = parseBuildingConnectedAuthState(stateStr);
    const subdomain = state.tenant;
    components[1] = `${subdomain}.${components[1]}`;
    logger.info('Redirecting to tenant', components.join('//'));
    return window.location.replace(components.join('//'));
  }
  return false;
};
