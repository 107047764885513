import newLogo from 'assets/img/concntric-logo-dark.svg';
import smallLogo from 'assets/img/concntric-logo-sm-dark.svg';
import { useSidebar } from 'hooks/ui';
import { Collapse, ListItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useCurrentUserCompanyLogo } from 'features/Company/hooks/company';

export const FooterListItem = () => {
  const { isSidebarCollapsed } = useSidebar();
  const {
    companyLogoQuery: { data: pictureData, logoData },
  } = useCurrentUserCompanyLogo();

  return pictureData || logoData ? (
    <ListItem
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        padding: (theme) => `0 ${theme.spacing(1)} ${theme.spacing(0.5)}`,
        mb: 1,
      }}
    >
      <Collapse orientation="horizontal" in={isSidebarCollapsed}>
        <Box
          component="img"
          src={smallLogo}
          alt="Small Concentric logo"
          sx={{ height: '2rem' }}
        />
      </Collapse>
      <Collapse orientation="horizontal" in={!isSidebarCollapsed} sx={{ pl: 1, mt: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="textDefaultBold" sx={{ color: 'white' }}>
            Powered by
          </Typography>
          <Box
            component="img"
            src={newLogo}
            alt="Concentric logo"
            sx={{ height: '2.5rem', width: '11.25rem' }}
          />
        </Box>
      </Collapse>
    </ListItem>
  ) : null;
};
