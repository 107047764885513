import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputAdornment,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Modal } from 'components/Modal/Modal';
import { FlyoutDialogTitleIcon } from 'components/FlyoutDialog/FlyoutDialogTitleIcon';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { DebouncedTextField } from 'components/DebouncedTextField/DebouncedTextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy, uniqBy } from 'lodash-es';
import { useProjects } from 'features/Projects/hook/project';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useFormikContext } from 'formik';
import { EditingProjectDashboard } from 'features/project-dashboard/helpers';
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRowSelectionModel,
} from '@mui/x-data-grid-premium';
import { metricPrefixFormatter } from 'utils/helpers';
import { GridColumns } from 'types/helpers';
import { DashboardRelatedProject } from 'types/Dashboard';
import { ProjectGroup } from 'types/Project';

type ProjectDashboardProjectsSectionManageProps = {
  groups: ProjectGroup[];
  projects: DashboardRelatedProject[];
  onClose: () => void;
};

const positionKey = 'ProjectDashboardProjectsSectionManage';

const defaultPosition = { x: 28, y: -24 };
export function ProjectDashboardProjectsSectionManage({
  groups,
  projects,
  onClose,
}: ProjectDashboardProjectsSectionManageProps) {
  const { values, setFieldValue } = useFormikContext<EditingProjectDashboard>();
  const { projectsQuery } = useProjects();
  const position = useMemo(() => {
    return sessionStorage.getItem(positionKey)
      ? JSON.parse(sessionStorage.getItem(positionKey) as string)
      : defaultPosition;
  }, []);
  const debouncedPosition = useDebouncedCallback((p) => {
    sessionStorage.setItem(positionKey, JSON.stringify(p));
  }, 1000);
  const [search, setSearch] = useState('');

  const rows = useMemo(() => {
    const result = [...projects];
    projectsQuery.data?.forEach((rp) => {
      const exist = projects.find((p) => p.id === rp.id);
      if (!exist) {
        result.push({ ...rp, has_access: true });
      }
    });

    return sortBy(result, (u) => u.name.toLowerCase());
  }, [projects, projectsQuery.data]);

  const groupsByProjects = groups.reduce(
    (acc, group) => {
      group.projects.forEach((p) => {
        const relatedGroups = acc[p.id] || [];
        acc[p.id] = [...relatedGroups, group];
      });
      return acc;
    },
    {} as Record<number, ProjectGroup[]>,
  );

  const filteredRows = search
    ? rows.filter((p) => p.name.toLowerCase().startsWith(search.toLowerCase()))
    : rows;

  const [checkedIds, setCheckedIds] = useState(
    values.project_ids as GridRowSelectionModel,
  );

  const columns: GridColumns<DashboardRelatedProject, string> = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: (params) => {
        const assignedGroups = groupsByProjects[params.row.id]
          ?.map((g) => g.name)
          .join(' , ');
        if (assignedGroups) {
          return (
            <Tooltip
              title={assignedGroups ? `Project is assigned to ${assignedGroups}` : ''}
              placement="right"
              arrow
            >
              <Box>
                <Checkbox checked disabled />
              </Box>
            </Tooltip>
          );
        }
        return (
          <Box>
            {!!GRID_CHECKBOX_SELECTION_COL_DEF.renderCell &&
              GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)}
          </Box>
        );
      },
      renderHeader: () => null,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 200,
    },
    {
      field: 'target_budget',
      headerName: 'Cost',
      valueFormatter: (value) =>
        value == null ? '-' : metricPrefixFormatter(value ?? 0),
    },
    {
      field: 'target_square_footage',
      headerName: 'GSF',
      valueFormatter: (value) =>
        value == null ? '-' : metricPrefixFormatter(value ?? 0),
    },
  ];

  return (
    <Modal
      defaultPosition={position as typeof defaultPosition}
      onDrag={({ x, y }) => {
        debouncedPosition({ x, y });
      }}
      onClose={onClose}
      sx={{ position: 'fixed', width: '900px' }}
    >
      <Box sx={{ px: 2, pt: 1, pb: 2 }}>
        <Box>
          <FlyoutDialogTitleIcon size="sm" icon={faPlus} />
          <Typography variant="h4" component="span">
            Add projects
          </Typography>
        </Box>
        <DebouncedTextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          placeholder="Quick Search"
          sx={{ mt: 1, width: '100%' }}
        />
        <Box
          sx={{
            height: 520,
            width: '100%',
            mt: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {projectsQuery.isLoading ? (
            <CircularProgress sx={{ m: 'auto', my: 8 }} />
          ) : (
            <DataGridPremium
              onRowSelectionModelChange={(selectionModel) => {
                setCheckedIds(selectionModel);
              }}
              rowSelectionModel={checkedIds}
              checkboxSelection
              disableColumnMenu
              disableColumnReorder
              columns={columns}
              rows={filteredRows}
              hideFooter
              sx={[
                !checkedIds.length && {
                  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                    {
                      display: 'none',
                    },
                },
              ]}
            />
          )}
        </Box>
        <Button
          sx={{ mt: 1 }}
          onClick={() => {
            const directProjects = rows.filter((p) => checkedIds.includes(p.id));
            const indirectProjects = uniqBy(groups.map((g) => g.projects).flat(), 'id');
            const allProjects = uniqBy([...directProjects, ...indirectProjects], 'id');

            setFieldValue('related_projects', allProjects);
            setFieldValue('projects', directProjects);
            setFieldValue('project_ids', checkedIds);

            onClose();
          }}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
}
