import { InternalAxiosRequestConfig } from 'axios';
import Keycloak from 'keycloak-js';
import logger from 'loglevel';

export class AuthenticationService {
  private static instance: AuthenticationService | null = null;

  keycloakInstance: Keycloak | undefined;
  isAuthenticated: boolean | undefined;

  private constructor() {}

  public static getInstance(): AuthenticationService {
    if (this.instance === null) {
      this.instance = new AuthenticationService();
    }
    return this.instance;
  }

  setKeycloakInstance(keycloakInstance: Keycloak) {
    this.keycloakInstance = keycloakInstance;
  }

  setIsAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }

  async interceptAxiosRequest(config: InternalAxiosRequestConfig) {
    const keycloakInstance = AuthenticationService.getInstance()?.keycloakInstance;
    if (keycloakInstance?.isTokenExpired()) {
      logger.debug('Refreshing token');
      await keycloakInstance.updateToken();
    }

    const token = keycloakInstance?.token;
    if (!token) {
      return config;
    }

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  }
}
