import { Theme, SxProps } from '@mui/material';
import {
  flatMap,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  keyBy,
  map,
  round,
  xorWith,
} from 'lodash-es';
import { UserCompany } from 'types/Company';
import { User } from 'types/User';
import { isDev, isPreview } from './environment';

type AddSearchParamsToUrlProps = {
  url: string;
  initialSearchParams?: string;
  searchParams?: Record<
    string,
    boolean | number | string | number[] | string[] | undefined | null
  >;
  appendBracesArray?: boolean;
};

export const addSearchParamsToUrl = ({
  url,
  initialSearchParams,
  searchParams,
  appendBracesArray = true,
}: AddSearchParamsToUrlProps) => {
  if (!searchParams && !initialSearchParams) {
    return url;
  }
  const urlSearchParams = new URLSearchParams(initialSearchParams);

  map(searchParams, (paramValue, paramKey) => {
    if (Array.isArray(paramValue)) {
      map(paramValue, (item) =>
        urlSearchParams.append(
          `${paramKey}${appendBracesArray ? '[]' : ''}`,
          typeof item !== 'string' ? String(item) : item,
        ),
      );
    } else if (paramValue !== undefined) {
      urlSearchParams.set(
        paramKey,
        typeof paramValue !== 'string' ? String(paramValue) : paramValue,
      );
    }
  });
  if (Array.from(urlSearchParams.keys()).length === 0) return url;

  return `${url}?${urlSearchParams.toString().replaceAll('%5B%5D=', '[]=')}`;
};

export const objectToQueryParam = (data: Record<string, unknown>) => {
  return Object.keys(data)
    .filter(
      (key) =>
        (data[key] && !isArray(data[key])) ||
        (isArray(data[key]) && (data[key] as number[]).length),
    )
    .map((key) => {
      let val = data[key];
      if (val !== null && isArray(val)) val = val.join(',');
      if (val !== null && typeof val === 'object')
        val = objectToQueryParam(val as Record<string, unknown>);
      return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`;
    })
    .join('&');
};

export const getRangedHashFromNumber = (seed: number, start: number, openEnd: number) => {
  const getHashCodeNumber = function (n: number) {
    const arr = new ArrayBuffer(8);
    const dv = new DataView(arr);
    dv.setFloat64(0, n);
    const c = dv.getInt32(0);
    const d = dv.getInt32(4);
    return c ^ d;
  };
  return (Math.abs(getHashCodeNumber(seed)) % (openEnd - start)) + start;
};

// https://en.wikipedia.org/wiki/Metric_prefix
export const metricPrefixFormatter = (num: number, digits = 1) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item?.symbol
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : num.toFixed(digits);
};

export const truncateString = (input: string, max = 10) => {
  if (input.length > max) {
    return input.substring(0, max) + '...';
  }
  return input;
};

export const convertBytesToMegaBytes = (bytes: number) => bytes / Math.pow(1024, 2);

export const concatIfNotEqualBy = <K>(original: K[], toAdd: K[], attr: keyof K) => {
  const originalHash = keyBy(original, attr);
  const response = [...original];
  toAdd.forEach((object) => {
    const key = object[attr] as string | number;
    if (!isEqual(object, originalHash[key])) {
      response.push(object);
    }
  });
  return response;
};

export const compareFloatNumbers = (
  a: number | null | undefined,
  b: number | null | undefined,
  precision = 6,
) => {
  return typeof a === 'number' && typeof b === 'number'
    ? round(a, precision) === round(b, precision)
    : a === b;
};

export const isNumeric = (value: string | number) =>
  isNumber(value) || (!isEmpty(value) && !isNaN(parseFloat(value)));

export const betterParseInt = (value: string | number) =>
  typeof value === 'string' ? parseInt(value) : value;

const validFileExtensions = {
  image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'],
  excel: ['xlsx', 'xls'],
};

export const isValidFileType = (
  fileName: string | undefined | null,
  fileType: keyof typeof validFileExtensions,
) => {
  if (!fileName) return false;
  const splitted = fileName.split('.');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return splitted.length > 1 && validFileExtensions[fileType].includes(splitted.at(-1)!);
};

export function joinWithCommasAndAnd(strings: string[]) {
  const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
  return formatter.format(strings);
}

export const isArrayEqual = <T = unknown>(x: T[], y: T[]) =>
  isEmpty(xorWith(x, y, isEqual));

const toForcedArray = (objectOrArray: unknown) => {
  if (Array.isArray(objectOrArray)) {
    return objectOrArray;
  }
  if (isNil(objectOrArray)) {
    return [];
  }
  return [objectOrArray];
};

export const joinSx = (...sxs: (SxProps<Theme> | undefined)[]) =>
  flatMap(sxs.map((sx) => toForcedArray(sx)));

export const getUserFullName = (
  user?: Partial<Pick<User, 'first_name' | 'last_name'>> | null,
) => {
  if (!user) return '';
  const { first_name, last_name } = user;
  return `${first_name ?? ''} ${last_name ?? ''}`.trim();
};

export const getUserFullnameOrEmail = (
  user: Partial<Pick<User, 'first_name' | 'last_name' | 'email'>>,
) => getUserFullName(user) || user.email || '';

export const getUserFullnameAndEmail = (
  user: Partial<Pick<User, 'first_name' | 'last_name' | 'email'>>,
) => {
  const fullName = getUserFullName(user);
  return fullName ? `${fullName} (${user.email})` : (user.email ?? '');
};

type GenericFilter<T> = (item: T) => boolean;
export const combineFilters =
  <T = unknown>(...filters: (GenericFilter<T> | null)[]) =>
  (item: T) =>
    filters.filter(Boolean).every((filter) => filter?.(item));

export const getInitials = (fullName: string, maxLength = 3) =>
  fullName
    .match(/(\b\S)?/g)
    ?.join('')
    .toUpperCase()
    .substring(0, maxLength) ?? '';

const previewTenant = localStorage.getItem('currentTenant');
export const setPreviewTenant = (tenant: string) =>
  localStorage.setItem('currentTenant', tenant);

export const getCurrentTenantSlug = () => {
  if (isPreview()) {
    return previewTenant;
  }
  const domains = new URL(window.location.href).host.split('.');

  if (isDev() && domains.length > 2 && domains.at(-1)?.includes('localhost')) {
    return domains[0];
  }

  // We expect an URL formed by the tenant slug subdomain, an environment subdomain,
  // the main domain and top level domain. E.g.: concntric.app-dev.concntric.com
  return domains.length === 4 ? domains[0] : null;
};

export const isExternalUser = (
  company: UserCompany | undefined,
  email: string | undefined,
) =>
  !company ||
  !email ||
  email.split('@').length < 2 ||
  !company.tld.split(',').some((domain) => domain.trim() === email.split('@')[1]);

export const parseCurrentTenantUrl = () => {
  let subdomain;
  const url = new URL(window.location.href);
  const host = url.host;
  if (isPreview()) {
    subdomain = previewTenant;
  } else {
    subdomain = host.split('.')[0];
  }
  return { url, host, subdomain };
};

export const sortMilestoneByDateFn = (
  a: { date?: string | number },
  d: { date?: string | number },
) => (a.date && d.date ? new Date(a.date).getTime() - new Date(d.date).getTime() : 0);

export const isElementInViewport = (el: Element) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const moveInArray = <T>(arr: T[], fromIndex: number, toIndex: number) => {
  if (
    !Array.isArray(arr) ||
    fromIndex < 0 ||
    fromIndex >= arr.length ||
    toIndex < 0 ||
    toIndex >= arr.length
  )
    return null;
  const copy = [...arr];
  const element = copy[fromIndex];
  copy.splice(fromIndex, 1);
  copy.splice(toIndex, 0, element);
  return copy;
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
