import { InputAdornment, Stack } from '@mui/material';
import { DebouncedTextField } from 'components/DebouncedTextField/DebouncedTextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { useProjectDashboardTableFilters } from 'features/project-dashboard/hooks/useProjectDashboardTableFilters';
import { ProjectGroupFilter } from 'features/project-dashboard/table/ProjectGroupFilter';
import { ProjectFilter } from 'features/project-dashboard/table/ProjectFilter';
import { MemberFilter } from 'features/project-dashboard/table/MemberFilter';
import { ProjectDashboadTypeFilter } from 'features/project-dashboard/table/ProjectDashboadTypeFilter';

export function ProjectDashboardsFilters() {
  const { searchValue, setSearchValue } = useProjectDashboardTableFilters();
  return (
    <Stack sx={{ gap: 1, flexWrap: 'wrap', flexDirection: 'row' }}>
      <DebouncedTextField
        key={searchValue ? 'search-field' : 'empty-search-field'}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        placeholder="Quick Search"
        sx={{ maxWidth: '8.5rem' }}
        autoFocus
      />
      <ProjectGroupFilter />
      <ProjectFilter />
      <MemberFilter />
      <ProjectDashboadTypeFilter />
    </Stack>
  );
}
