import newLogo from 'assets/img/concntric-logo-dark.svg';
import smallLogo from 'assets/img/concntric-logo-sm-dark.svg';
import { useSidebar } from 'hooks/ui';
import { Collapse, ListItem } from '@mui/material';
import { Box } from '@mui/system';
import { useCurrentUserCompanyLogo } from 'features/Company/hooks/company';

export const LogoListItem = () => {
  const { isSidebarCollapsed } = useSidebar();
  const {
    companyLogoQuery: { data: pictureData, logoData },
  } = useCurrentUserCompanyLogo();

  return (
    <ListItem
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        padding: (theme) => `0 ${theme.spacing(1)} ${theme.spacing(0.5)}`,
      }}
    >
      <Collapse orientation="horizontal" in={isSidebarCollapsed}>
        <Box
          component="img"
          src={logoData ? logoData.data : smallLogo}
          alt="Small Concentric logo"
          sx={{ height: '2rem' }}
        />
      </Collapse>
      <Collapse orientation="horizontal" in={!isSidebarCollapsed} sx={{ pl: 1 }}>
        <Box
          component="img"
          src={pictureData ? pictureData.data : newLogo}
          alt="Concentric logo"
          sx={{ height: '2.5rem', width: '11.25rem', objectFit: 'cover' }}
        />
      </Collapse>
    </ListItem>
  );
};
