import { atom, useAtom } from 'jotai';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { useState } from 'react';
import { BasicDownloadOptions } from 'types/Reports';
import { addSearchParamsToUrl } from 'utils/helpers';
import { DesignMilestone } from 'types/DesignMilestones';
import { config } from '../helpers';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';

const estimateReportFlyoutAtom = atom(false);
estimateReportFlyoutAtom.debugLabel = 'estimateFlyoutsAtom';

const isFullEstimateReportAtom = atom(false);
isFullEstimateReportAtom.debugLabel = 'isEstimateFullEstimateAtom';

export const useEstimateReport = () => {
  const { infoToast, errorToast, successToast } = useToastDialogs();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isEstimateReportModalOpen, setIsEstimateReportModalOpen] = useAtom(
    estimateReportFlyoutAtom,
  );
  const [isFullEstimateReport, setIsFullEstimateReport] = useAtom(
    isFullEstimateReportAtom,
  );
  const { selectedProjectId } = useSelectedProjectId();

  const download = async (
    milestone: DesignMilestone,
    options: BasicDownloadOptions & { per_component?: boolean },
  ) => {
    if (typeof milestone.id !== 'number' || milestone.id < 0) {
      throw Error();
    }
    const endpoint = options.per_component
      ? Resources.FULL_ESTIMATE_REPORT_DOWNLOAD
      : Resources.ESTIMATE_REPORT_DOWNLOAD;
    let url = endpoint
      .replace('<int:pk>', milestone.id.toString())
      .replace('<int:project_pk>', String(selectedProjectId));

    url = addSearchParamsToUrl({
      url,
      searchParams: {
        type: options.type ?? 'pdf',
      },
    });

    setIsDownloading(true);

    infoToast({ title: 'Downloading..', text: 'We are preparing your report.' });

    try {
      const response = await ApiService.get(url, config);
      if (!response) {
        return;
      }
      const file_url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = file_url;
      link.setAttribute('download', `${milestone.name}.${options.type ?? 'pdf'}`);
      document.body.appendChild(link);
      link.click();
      successToast({
        title: 'Report Downloaded',
        text: 'Report downloaded successfully',
      });
      setIsDownloading(false);
      return response;
    } catch (error) {
      setIsDownloading(false);
      const message =
        error instanceof Error
          ? error.message
          : 'The service is temporarily unavailable. Try again later.';

      errorToast({
        text: message,
      });
    }
  };

  return {
    download,
    isFullEstimateReport,
    isDownloading,
    isEstimateReportModalOpen,
    setIsEstimateReportModalOpen,
    setIsFullEstimateReport,
  };
};
